import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/js/utils.js';
import cloneDeep from 'lodash.clonedeep';
import { globalEventProperties } from '../../../../../Common/Resources/src_front/js/mixpannel/globalEventProperties';
import mixpanel from 'mixpanel-browser';

export const initPhoneMask = () => {
    // VARS
    const locationForms = document.querySelectorAll('[data-phone-number-form]');
    if (!locationForms.length) return;
    const telInputs = document.querySelectorAll('[data-tell-input]');
    const userCountryCode = document.querySelector('[data-user-country]')?.dataset.userCountry.trim();
    const locationContainer = document.querySelector('[data-location]');

    // LISTENERS
    locationForms.forEach((form, index) => {
        window[`phoneMask_${index}`] = intlTelInput(telInputs[index], {
            separateDialCode: true,
            initialCountry: userCountryCode,
            nationalMode: false,
            autoPlaceholder: 'polite',
        });

        setAttributeForMask(telInputs[index]);
        phoneMask(telInputs[index], index);

        telInputs[index].addEventListener('countrychange', function () {
            locationForms[index].classList.remove('error');
            this.value = '';
            this.blur();
            if (locationContainer) {
                locationContainer.dataset.location = window[`phoneMask_${index}`].defaultCountry;
            }
            setAttributeForMask(telInputs[index]);
        });

        telInputs[index].addEventListener('input', function () {
            locationForms[index].classList.remove('error');
        });

        telInputs[index].addEventListener('focus', function () {
            locationForms[index].classList.remove('error');
        });
    });

    // FUNCTIONS
    function phoneMask(phoneInput, index) {
        if (!phoneInput) return false;

        // LISTENERS
        phoneInput.addEventListener('input', initMask, false);
        phoneInput.addEventListener('focus', initMask, false);
        phoneInput.addEventListener(
            'blur',
            function () {
                const mask = this.dataset.phoneMask;
                const currentDefaultMask = mask.substring(0, mask.indexOf('_'));
                const props = cloneDeep(globalEventProperties);

                if (this.value.length === currentDefaultMask.length) {
                    this.value = '';
                }

                props.input_value = window[`phoneMask_${index}`].getNumber();
                props.input_class = 'tel';
                mixpanel.track('type_input', props);
            },
            false,
        );

        // FUNCTIONS & METHODS
        function initMask(event) {
            const mask = this.dataset.phoneMask;
            const currentDefaultMask = mask.substring(0, mask.indexOf('_'));

            // insert default mask if user try delete him
            const pos = this.selectionStart;
            if (pos < currentDefaultMask.length && event.inputType === 'deleteContentBackward') {
                event.preventDefault();
                this.value = currentDefaultMask;
                this.selectionStart = currentDefaultMask.length;
            }

            let i = 0;
            const def = mask.replace(/\D/g, '');

            const val = this.value.replace(/\D/g, '');
            let new_value = mask.replace(/[_\d]/g, function (a) {
                return i < val.length ? val.charAt(i++) || def.charAt(i) : a;
            });

            i = new_value.indexOf('_');

            if (i !== -1) {
                new_value = new_value.slice(0, i);
            }

            let reg = mask
                .substr(0, this.value.length)
                .replace(/_+/g, function (a) {
                    return '\\d{1,' + a.length + '}';
                })
                .replace(/[+()]/g, '\\$&');

            reg = new RegExp('^' + reg + '$');

            if (!reg.test(this.value) || this.value.length < currentDefaultMask.length) {
                this.value = new_value;
            }
        }
    }

    function setAttributeForMask(input) {
        const placeholder = input.getAttribute('placeholder');
        const countryData = window[`phoneMask_${Array.from(telInputs).indexOf(input)}`].getSelectedCountryData();
        if (countryData.iso2 === 'kh') {
            input.dataset.phoneMask = '__ ___ ____';
        } else {
            input.dataset.phoneMask = placeholder.replace(/[0-9]/gi, '_');
        }
    }
};
